import React from 'react'
import { CNavItem } from '@coreui/react'
import { ReactComponent as CustomerIcon } from '../../assets/icons/Customer_sidebar.svg';
import { ReactComponent as StudentIcon } from '../../assets/icons/studentManagment.svg';
import { ReactComponent as OrderIcon } from '../../assets/icons/orderManager.svg';
import { ReactComponent as CategoryIcon } from '../../assets/icons/Category.svg';
import { ReactComponent as RecQueriesIcon } from '../../assets/icons/reseiveQueries.svg';
import { ReactComponent as NotifictionIcon } from '../../assets/icons/navigation_sidebar.svg';



const _nav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/',
  },
  {
    component: CNavItem,
    name: 'Customer Management',
    to: '/customer-detail',
    icon: <CustomerIcon />,
  },
  {
    component: CNavItem,
    name: 'Student Management',
    to: '/student-management',
    icon: <StudentIcon />,
  },
  {
    component: CNavItem,
    name: 'Order Management',
    to: '/order-management',
    icon: <OrderIcon />,
  },
  // {
  //   component: CNavItem,
  //   name: 'Category',
  //   to: '/category',
  //   icon: <CategoryIcon />,
  // },
  // {
  //   component: CNavItem,
  //   name: 'Receive Queries',
  //   to: '/receive-queries',
  //   icon: <RecQueriesIcon />,
  // },

  // for notification
  // {
  //   component: CNavItem,
  //   name: 'Notifications',
  //   to: '/notifications',
  //   icon: <NotifictionIcon />,
  // },
  
  // {
  //   component: CNavGroup,
  //   name: 'Base',
  //   to: '/base',
  //   icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'Accordion',
  //       to: '/base/accordion',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Breadcrumb',
  //       to: '/base/breadcrumbs',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Cards',
  //       to: '/base/cards',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Carousel',
  //       to: '/base/carousels',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Collapse',
  //       to: '/base/collapses',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'List group',
  //       to: '/base/list-groups',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Navs & Tabs',
  //       to: '/base/navs',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Pagination',
  //       to: '/base/paginations',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Placeholders',
  //       to: '/base/placeholders',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Popovers',
  //       to: '/base/popovers',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Progress',
  //       to: '/base/progress',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Spinners',
  //       to: '/base/spinners',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Tables',
  //       to: '/base/tables',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Tooltips',
  //       to: '/base/tooltips',
  //     },
  //   ],
  // },
  
  
  
]

export default _nav
