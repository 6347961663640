import "./navbar.scss";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
// import AppBreadcrumb from "../breadcrump/Breadcrumb";
import { ReactComponent as MenuIcon } from '../../assets/icons/Menu_topbar.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/arrowDown.svg';
import { CHeader, CHeaderToggler, CContainer, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem } from "@coreui/react";

import CIcon from "@coreui/icons-react";
import { cilUser } from "@coreui/icons";

import {logout} from '../../HigherOrderComponents/ApiCalls/authentication/Logout'


const Navbar = () => {

  const dispatch = useDispatch()
  const sidebarShow = useSelector((state) => state.sidebarShow)
  const adminDetail = useSelector((state) => state.adminDetails);

  //for navigation
  let navigate = useNavigate();

  const handleLogOut = async () => {
		// debugger
		const data = {
			email: JSON.parse(localStorage.getItem('sparrowSignIn'))?.user?.email
		}
		const resp = await logout(data);
		if (resp.status !== 200) {
			localStorage.removeItem('sparrowSignIn');
			navigate('/Login');

		} else {
			
			localStorage.removeItem('sparrowSignIn');
			navigate('/Login');
		}
		
	}


  return (
    <CHeader position="sticky" className="mb-2">
      <div className="navbar">
        <CContainer fluid>
          <div className="wrapper_navbar">
            <div>
              <CHeaderToggler
              className="ps-1"
              onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
              >
                <MenuIcon/>
              </CHeaderToggler>
              {/* <Link to="" className="ps-3"> 
                <img src="../assets/images/icons/search_topbar.svg" alt="saerch_icon" />   
              </Link>  */}
            </div>
            <div className="items">
              
              {/* <div className="item">
                <Link to="/notifications"> 
                  <img src="../assets/images/icons/bellIcon.svg" alt="noticfication_icon" />   
                </Link>
              </div> */}
              
              <div className="item">
                <img
                  src="https://images.pexels.com/photos/941693/pexels-photo-941693.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
                  alt=""
                  className="avatar"
                />
                <span className="text_topbar">Hi, {adminDetail?.first_name && adminDetail?.first_name} {adminDetail?.last_name && adminDetail?.last_name.charAt(0).toUpperCase()}.</span>
                {/* code new */}
                <CDropdown variant="nav-item">
                  <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
                    <ArrowDownIcon />
                  </CDropdownToggle>
                  <CDropdownMenu className="pt-0" placement="bottom-end">
                    
                    <CDropdownItem onClick={handleLogOut}>
                      <CIcon icon={cilUser} className="me-2" />
                      Log Out
                    </CDropdownItem>
                  </CDropdownMenu>
                </CDropdown>
              </div>
            </div>
          </div>
        </CContainer>
      </div>
      {/* <CContainer fluid> */}
        {/* <AppBreadcrumb /> */}
      {/* </CContainer> */}
    </CHeader>
  );
};

export default Navbar;
