import { BrowserRouter, Routes, Route , Navigate} from 'react-router-dom';
import React, { Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Index_page from './pages/Index_page';
import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Pages
const Login = React.lazy(() => import('./pages/login_pages/Login_form'))
const Home = React.lazy(() => import('./pages/home/Home'))

const CustomerList = React.lazy(() => import('./pages/customer_pages/Customer_table'))
const SingleCustomer = React.lazy(() => import('./pages/customer_pages/Single_CustomerDetail'))

const StudentList = React.lazy(() => import('./pages/student_pages/StudentDatatable'))
const SingleStudent = React.lazy(() => import('./pages/student_pages/Single_StudentDetail'))

const OrderList = React.lazy(() => import('./pages/order_pages/OrderDatatable'));
const SingleOrder = React.lazy(() => import('./pages/order_pages/Single_OrderDetail'));

const Notification = React.lazy(() => import('./pages/notifiction/NotificationPage'))




const App = () => {

  const dispatch = useDispatch()
  const adminDetail = useSelector((state) => state.adminDetails);
  let currentUser = localStorage.getItem("sparrowSignIn") && JSON.parse(localStorage.getItem("sparrowSignIn"));

  if(Object.keys(adminDetail).length === 0 &&  currentUser){
    // after refresh redux array will be empty so we check and fill array
      const redux_obj = {
        id: currentUser?.user?.id,
        fireBase_token : currentUser?.user?.fcm_token,
        token:  currentUser?.token,
        first_name: currentUser?.user?.first_name,
        last_name: currentUser?.user?.last_name,
        email: currentUser?.user?.email,
        image: currentUser?.user?.image
      };
      dispatch({ type: 'setAdmin', adminDetails: redux_obj })
  }
  const RequireAuth = ({ children }) => {

    return Object.keys(adminDetail).length !== 0 ? <Index_page page_route={children} /> : <Navigate to="/Login" />;
  };

  
  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route path='*' element={<Navigate to={'/'}/>} />
          <Route path="/login" element={<Login />} />

          <Route path="/">
            <Route
              index
              element={
                <RequireAuth>
                  <Home />
                </RequireAuth>
              }
            />
            <Route path="/customer-detail">
              <Route
                index
                element={
                  <RequireAuth>
                    <CustomerList />
                  </RequireAuth>
                }
              />
              <Route
                path=":userId"
                element={
                  <RequireAuth>
                    <SingleCustomer />
                  </RequireAuth>
                }
              />
            </Route>

            {/* for student route */}
            <Route path="/student-management">
              <Route
                index
                element={
                  <RequireAuth>
                    <StudentList />
                  </RequireAuth>
                }
              />
              <Route
                path=":userId"
                element={
                  <RequireAuth>
                    <SingleStudent />
                  </RequireAuth>
                }
              />
            </Route>

            {/* for Order Route */}
            <Route path="/order-management">
              <Route
                index
                element={
                  <RequireAuth>
                    <OrderList />
                  </RequireAuth>
                }
              />
               <Route
                path=":userId"
                element={
                  <RequireAuth>
                    <SingleOrder />
                  </RequireAuth>
                }
              />
            </Route>

            {/* for notification route */}
            <Route path="/notifications">
              <Route
                index
                element={
                  <RequireAuth>
                    <Notification />
                  </RequireAuth>
                }
              />
            </Route>


            {/* not to be use
            <Route path="products">
              <Route
                index
                element={
                  <RequireAuth>
                    <List />
                  </RequireAuth>
                }
              />
              <Route
                path=":productId"
                element={
                  <RequireAuth>
                    <Single />
                  </RequireAuth>
                }
              />
              <Route
                path="new"
                element={
                  <RequireAuth>
                    <New inputs={productInputs} title="Add New Product" />
                  </RequireAuth>
                }
              />
            </Route> */}
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
