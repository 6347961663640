import axios from "axios";

const liveServer = "https://apis.findsparrow.com/api";
const localServer = "http://localhost/sparrow-backend/api";
const currentServer = liveServer;

const instance = axios.create({
  baseURL: currentServer,
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
});

export default instance;
