// import { useState } from "react";

import { useSelector, useDispatch } from 'react-redux'
import { Link } from "react-router-dom";
import { CSidebar, CSidebarBrand, CSidebarNav } from "@coreui/react";
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import { AppSidebarNav } from "./AppSidebarNav";

// sidebar nav config
import _nav from "./_nav";


const Sidebar = () => {

  const dispatch = useDispatch()
  const sidebarShow = useSelector((state) => state.sidebarShow)


  return (

    <CSidebar
      position="fixed"
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: 'set', sidebarShow: visible })
      }}
    >
      <CSidebarBrand className="d-none d-md-flex" to="/">
        <Link to="/" className="sidebar-brand-full">
          <img className='' src="/assets/images/logo_images/dashboard.svg" alt="parrot" />
        </Link>
        <Link to="/" className="sidebar-brand-narrow">
          <img className='sidebar-brand-narrow' src="./assets/images/logo_images/icon_logo.svg" alt="parrot" />
        </Link>
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={_nav} />
        </SimpleBar>
      </CSidebarNav>
      
    </CSidebar>
  );
};

export default Sidebar;
