import React from 'react'
import Sidebar from '../Components/sidebar/Sidebar';
import Navbar from '../Components/navbar/Navbar';
// import AppBreadcrumb from '../Components/breadcrump/Breadcrumb';

const Index_page = (props) => {
  return (
    <div className="home">
      <Sidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light homeContainer">
        <Navbar />
        <div>
          {props.page_route}
        </div>
      </div>
    </div>
    
  )
}

export default Index_page